import React from "react"

import Section from "../components/section"
import imageOne from "../images/chi-siamo.jpg"
import imageTwo from "../images/attivita.jpg"

const IndexPage = () => (
    <>
        <h1>
        Un secolo di passione <br/>per l'avvocatura
        </h1>
        <Section
        order="1"
        side="left"
        sidemobile="left"
        title="Chi siamo"
        img={imageOne}
        text="Erano i primi del Novecento quando l’Avvocato Oscar Pacchioni fondava a Modena il nostro Studio Legale. Iniziava così una storia di passione per l’avvocatura che perdura tuttora."
        btn1="IL TEAM"
        btn2="CONTATTI"
        link1="/chi-siamo"
        link2="/contatti"
        />
        <Section
        order="2"
        side="right"
        sidemobile="right"
        title="Aree di attività"
        img={imageTwo}
        text="Riteniamo che affrontare i problemi che ci vengono sottoposti con approccio sinergico e innovativo ci permetta di fornire il più efficace supporto legale."
        btn1="APPROFONDISCI"
        link1="/aree-di-attivita"
        />
    </>
)

export default IndexPage
