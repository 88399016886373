import React from "react"

import sectionStyles from "./section.module.scss"
import Button from "./button"

const IndexSec = (props) => (
  <section className={sectionStyles.section + " margin-neg-" + props.side}>
    <div className="row section no-gutters">
        <div className={"col-md-6 col-11 px-0 order-md-" + props.order + " " + props.side}>
          <figure>
          <img className="" src={props.img} alt={props.img}/>
          </figure>
        </div>
        <div className={"col-md-6 col-11 order-1 " + props.side}>
          <section className={sectionStyles.infocontainer + " " + props.side}>
            <div className={sectionStyles.info}>
            <h2>{props.title}</h2>
            <hr/>
            <p>{props.text}</p>
            <div className="buttons">
              {props.btn1 && <Button
                text={props.btn1}
                link={props.link1}
              /> }
              {props.btn2 && <Button
                text={props.btn2}
                link={props.link2}
              />}
              </div>
            </div>
          </section>
        </div>
      </div>
  </section>
)

/*

  style={{
        order: `${props.order}`,
      }}

*/

export default IndexSec
